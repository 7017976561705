import {useState} from "react";

import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {Container, FaucetForm} from "./faucetStyling";
import Utility from "../utility";
import {httpService} from "../manager/httpManager";
import {httpConstants} from "../constants";
import ReCaptcha from "@matt-block/react-recaptcha-v2";

function Faucet() {
    const [address, setAddress] = useState("")
    const [isLoading, setLoading] = useState(false)
    const [captchaVerified, setCaptchaVerification] = useState(false)

    async function onSubmit(e) {
        e.preventDefault()
        if (Utility.validateAddress(address)) return Utility.failureToast("Invalid Address")
        if (!captchaVerified) return Utility.failureToast("Please verify reCAPTCHA")
        setLoading(true)
        const [error, response] = await Utility.parseResponse(
            httpService(httpConstants.METHOD_TYPE.POST, httpConstants.API_END_POINT.FAUCET + `?address=${address}`)
        )
        setCaptchaVerification(false)
        setLoading(false)
        window.grecaptcha?.reset()

        if (error || !response)
            return Utility.failureToast("Unable to add requested amount")
        return Utility.successToast("MILLIs transferred!")
    }

    return (
        <Container>
            <FaucetForm onSubmit={onSubmit}>
                <p>MILLICENT TESTNET FAUCET</p>
                <input placeholder="Account Address" name="address" value={address}
                       onChange={e => setAddress(e.target.value)}/>
                <span>Please use http://millicentexplorer.com to explore Millicent testnet.</span>
                {/*siteKey to be replaced*/}
                <ReCaptcha
                    siteKey="6Ldz39ghAAAAAODnV-2KkpUjLyuwLR0U8SD7PSL7"
                    theme="light"
                    size="normal"
                    onSuccess={(captcha) => setCaptchaVerification(true)}
                    onExpire={() => console.log("Verification has expired, re-verify.")}
                    onError={() => console.log("Something went wrong, check your connection")}
                />
                <button type="submit">
                    {isLoading ? "Requesting . . . " : "REQUEST 100 MILLI"}
                </button>
            </FaucetForm>
            <ToastContainer/>
        </Container>
    );
}

export default Faucet;
