import {toast} from "react-toastify";

const utility = {
    successToast,
    failureToast,
    warnToast,
    validateAddress,
    parseResponse,
}
export default utility;

function parseResponse(promise) {
    return promise
        .then((data) => {
            return [null, data];
        })
        .catch((err) => [err]);
}

export const dispatchAction = (type, data) => {
    return (dispatch) => dispatch({type, data});
};

function successToast(message) {
    toast.success(message, {
        hideProgressBar: true,
        theme: "colored"
    });
}

function failureToast(message) {
    toast.error(message, {
        hideProgressBar: true,
        theme: "colored"
    });
}

function warnToast(message) {
    toast.warn(message, {
        hideProgressBar: true,
        theme: "colored"
    });
}

function validateAddress(address) {
    return !address || address.length !== 44 || !address.includes("milli")
}
