/**
 * Created by Ayush Kulshrestha on 18/09/2019.
 */

export const httpConstants = {
    METHOD_TYPE: {
        POST: "POST",
        PUT: "PUT",
        GET: "GET",
        DELETE: "DELETE",
    },
    CONTENT_TYPE: {
        APPLICATION_JSON: "application/json",
        MULTIPART_FORM_DATA: "multipart/form-data",
        APPLICATION_FORM_URLENCODED: "application/x-www-form-urlencoded",
        IMAGE_PNG: "image/png",
    },
    DEVICE_TYPE: {
        WEB: "web",
    },
    // API_BASE_ADDRESS: "http://localhost:3010/",
    API_BASE_ADDRESS: "http://34.105.196.102:3010/",
    // API_BASE_ADDRESS: "http://34.89.42.246:3010/",
    API_END_POINT: {
        FAUCET: "faucet",
    },
};
