import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
`
export const FaucetForm = styled.form`
  width: 320px;
  background: white;
  border-radius: 4px;
  border: 1px solid lightgrey;
  padding: 30px;
  margin: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p {
    font-weight: bolder;
    text-align: center;
    font-size: 16px;
    color: black;
    margin: 15px 0 45px 0;
  }

  input {
    padding: 10px 15px;
    width: 90%;
    outline: none;
    font-size: 13px;
    border: 1px solid lightgrey;
  }

  span {
    color: #8197a2;
    font-size: 14px;
    margin: 10px 0;
    text-align: center;
  }

  button {
    padding: 8px 14px;
    background: black;
    color: white;
    outline: none;
    border: none;
    margin: 15px 0;
    font-size: 13px;
    font-weight: 700;
    cursor: pointer;
    border-radius: 4px;
  }
`
