import {httpConstants} from "../constants";

export const httpService = (method, endPoint, headers, data) => {
    const requestOptions = {
        method: method,
        headers: headers || {'Content-Type': 'application/json'}
    };

    if (method !== httpConstants.METHOD_TYPE.GET)
        requestOptions.body = JSON.stringify(data);

    return new Promise((resolve, reject) => {
        fetch(httpConstants.API_BASE_ADDRESS + endPoint, requestOptions)
            .then(function handleResponse(response) {
                return response.text().then(text => {
                    const data = text && JSON.parse(text);
                    resolve(data);
                });
            }).catch(function (err) {
            reject(err);
        })
    })

}
